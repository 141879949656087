import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

// Layout component
import Layout from '../components/layouts/Layout';
import BannerPicture from '../components/BannerPicture';
import TwoColumnsIcons from '../components/TwoColumnsIcons';
import CustomersLogos from '../components/CustomersLogos';
import CardCustomer from '../components/CardCustomer';
import BackgroundPicture from '../components/BackgroundPicture';
import SectionOnlyFooter from '../components/SectionOnlyFooter';

const TwoColumnsLayout = styled.div`
    margin: 7% 0%;
`;
const VideoIframe = styled.div`
    position: relative;
    padding-bottom: 53%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 10px;
    margin: 7% 0%;
`;

const IframeVideo = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
`;

const CardCustomerLayout = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content:space-between;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-x;
    margin-bottom:7%;
`;


const IndexPage = ({ data }) => {
    const { t } = useTranslation('Sport');

    const icons = [{
        icon: 'icon-map1',
        text: t('TITLE_NEIGHBORDHOOD')
    }, {
        icon: 'icon-location22',
        text: t('TITLE_AROUND_ME')
    },
    {
        icon: 'icon-plane',
        text: t('TITLE_LIVE_EVENT')
    }];

    return (
        <React.Fragment>
            <Helmet defer={false}>
                <title>{t('META_TITLE_SPORT')}</title>
                <meta name="description"
                    content={t('META_DESCRIPTION_SPORT')}/>
            </Helmet>
            <Layout>
                <BackgroundPicture
                    image={data.background.childImageSharp}
                    alt='soccer sneakers on field'
                />
                <div className='wemap-page-index'>
                    <h1 className='wemap-page-index-title'
                        dangerouslySetInnerHTML={{ __html: t('TITLE') }}></h1>

                    <CustomersLogos
                        title={t('TITLE_POWER_SPORT')}
                        size='small'
                        customersLogos={[
                            {image: '/images/industries/sport/logo-Tottenham.png'},
                            {image: '/images/industries/events/fff.png'},
                            {image: '/images/industries/sport/psg.svg'},
                            {image: '/images/industries/events/ffbb.svg'},
                            {image: '/images/industries/sport/om.svg'},
                            {image: '/images/industries/sport/ministere_des_sports.png'},
                            {image: '/images/industries/sport/vendee-globe.svg'},
                            {image: '/images/industries/sport/roland-garros-logo.png'}
                        ]}
                    />

                    <VideoIframe>
                        <IframeVideo title="youtube"
                            width="101%"
                            height="100%"
                            src="https://www.youtube.com/embed/x3TwwHgpnqc"
                            frameborder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen>
                        </IframeVideo>
                    </VideoIframe>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_DRIVE_FANS')}
                        pictureRight={data.sport.childImageSharp}
                        altDescription='computer view with Wemap map of sport club'
                        titleButton={t('BUTTON_GET_STARTED')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-export',
                                text: t('TITLE_MATCH')
                            },
                            {
                                icon: 'icon-checkmark',
                                text: t('TITLE_ACTUALITY')
                            },
                            {
                                icon: 'icon-tools',
                                text: t('TITLE_SERVICES')
                            }
                        ]}
                    />

                    <TwoColumnsLayout>
                        <TwoColumnsIcons title={t('TITLE_BUILD_COMMUNITY')}
                            text={t('TITLE_AT_HOME')}
                            titleButton={t('BUTTON_TALK_ABOUT_PROJECT')}
                            leftIcon={data.sportPersona.childImageSharp}
                            icons={icons}
                            urlFooter='/contact/'
                        />
                    </TwoColumnsLayout>

                    <SectionOnlyFooter
                        titleSection={t('TITLE_FANS_TRUST')}
                        cellParagraph={t('TITLE_REVENUS')}
                        pictureRight={data.omSport.childImageSharp}
                        altDescription='mobile with Wemap map of pinpoints'
                        titleButton={t('BUTTON_DISCOVER_WEMAP_PLATFORM')}
                        widthPicture='400px'
                        urlFooter='/contact/'
                        iconsOnlyFooterComponent={[
                            {
                                icon: 'icon-tools',
                                text: t('TITLE_NAT_ADS')
                            },
                            {
                                icon: 'icon-file-video',
                                text: t('TITLE_DISPLAY_ADS')
                            },
                            {
                                icon: 'icon-camera',
                                text: t('TITLE_REFERRAL')
                            }
                        ]}
                    />

                    <CardCustomerLayout>
                        <CardCustomer
                            url='https://www.tottenhamhotspur.com/we-map-mobile-test/'
                            picturecard={data.tottenham.childImageSharp}
                            altPictureCard='Logo de Tottenham'
                            title={t('TITLE_TOTTENHAM')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_TOTTENHAM_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_TOTTENHAM_POINT'),
                                    infoView: t('TITLE_TOTTENHAM_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.psg.fr/fans/fan-clubs#/search@-0.0000000,0.0000000,3.00'
                            picturecard={data.psg.childImageSharp}
                            altPictureCard='Logo de PSG'
                            title={t('TITLE_PSG')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_PSG_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_PSG_POINT'),
                                    infoView: t('TITLE_PSG_VIEW')
                                }
                            ]}
                        />

                        <CardCustomer
                            url='https://www.fff.fr/13-les-pratiques-loisirs/index.html'
                            picturecard={data.fff.childImageSharp}
                            altPictureCard='Logo de FFF'
                            title={t('TITLE_FFF')}
                            by={t('TEXT_BY')}
                            text={t('TITLE_FFF_BY')}
                            infosCard={[
                                {
                                    infoPoint: t('TITLE_FFF_POINT'),
                                    infoView: t('TITLE_FFF_VIEW')
                                }
                            ]}
                        />

                    </CardCustomerLayout>

                    <BannerPicture
                        backgroundBanner={data.banner.childImageSharp}
                        alt='Footer banner with view inside football game'
                        titleBannerPicture={t('TITLE_BANNER')}
                        titleButton={t('TITLE_BUTTON_TRY_OUT_NOW')}
                        buttonUrl='/contact/' />

                </div>
            </Layout>
        </React.Fragment>
    );
};

IndexPage.propTypes = {data: PropTypes.object};

export default IndexPage;

export const query = graphql`{
  background: file(relativePath: {eq: "background-image/sport-bg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  banner: file(relativePath: {eq: "industries/sport/banner_sport.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  sport: file(relativePath: {eq: "industries/sport/wemap-sport.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 550, layout: FIXED)
    }
  }
  sportPersona: file(relativePath: {eq: "industries/sport/sport-wemap.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 200, layout: CONSTRAINED)
    }
  }
  omSport: file(relativePath: {eq: "industries/sport/om-sport-wemap.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, width: 400, layout: FIXED)
    }
  }
  tottenham: file(
    relativePath: {eq: "industries/sport/card-horizontal-tottenham.png"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  psg: file(relativePath: {eq: "industries/sport/card-horizontal-psg.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
  fff: file(relativePath: {eq: "industries/sport/card-horizontal-fff.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 75, layout: FULL_WIDTH)
    }
  }
}
`;
